import {  TextField, Button } from '@mui/material'
import { useState } from 'react'

const SettingsForm = ({update_settings, settings}) => {
    const [from_name, set_from_name] = useState(settings.from_name ? settings.from_name : "")
    const [from_email, set_from_email] = useState(settings.from_email ? settings.from_email : "")
    const [email_password, set_email_password] = useState("")
    const [to_emails, set_to_emails] = useState(settings.to_email ? settings.to_email.toString() : [])
    const [mail_url, set_mail_url] = useState(settings.mail_server_addr ? settings.mail_server_addr : "")
    const [mail_port, set_mail_port] = useState(settings.mail_server_port ? settings.mail_server_port : "")

    function send_form(event){
        event.preventDefault();
        let settings = {
            instruction: "changeSettings",
            site_pass: "",
            from_email: from_email,
            to_email: to_emails.split(/[\s+,|]+/),
            from_name: from_name,
            email_password: email_password,
            mail_server_addr: mail_url,
            mail_server_port: mail_port
        }

        update_settings(settings)
    }

    return (
        <form style={{display: 'flex', flexDirection: 'column', padding: '30px 20px 0px 20px'}} onSubmit={send_form}>
            <TextField id="name" label="From name" sx={{marginBottom: '10px'}} onChange={(e) => set_from_name(e.target.value)} value={from_name}/>
            <TextField label="From email" sx={{marginBottom: '10px'}} onChange={(e) => set_from_email(e.target.value)} value={from_email}/>
            <TextField type='password' label="Email password" sx={{marginBottom: '10px'}} onChange={(e) => set_email_password(e.target.value)} value={email_password}/>
            <TextField label="To email" sx={{marginBottom: '10px'}} onChange={(e) => set_to_emails(e.target.value)} value={to_emails}/>
            <TextField label="Mailserver URL" sx={{marginBottom: '10px'}} onChange={(e) => set_mail_url(e.target.value)} value={mail_url}/>
            <TextField label="Mailserver port" sx={{marginBottom: '10px'}} onChange={(e) => set_mail_port(e.target.value)} value={mail_port}/>
            <Button type='submit' variant='contained'>Save</Button>
        </form>
    )

}

export default SettingsForm