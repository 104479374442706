const colors = {
    default_background: '#2E2C28',
    primary: '#006c46',
    primary_hover: '#DFB600',
    on_primary: '#ffffff',
    secondary: '#4e6356',
    secondary_hover: '#3f4d44',
    on_secondary: '#ffffff',
    tertiary: '#3c6472',
    tertiary_hover: '#2c4852',
    on_tertiary: '#ffffff',
    secondary_container: '#d0e8d7',
    info_border: '#5E5E5E',
    alert_error_text: '#E9BEBF',
    button_disabled: '#454545',
    error_border : '#EF5350',
    green_button: '#12b87d',
    red_text_button: '#bf3b32'
};

module.exports = { colors }