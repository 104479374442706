import { useState } from 'react'
import { colors } from '../constants'
import { sha256 } from 'js-sha256'
const { Box, ThemeProvider, Paper, TextField, Button } = require("@mui/material")

const Login = ({setPassword, theme}) => {
    const [password, set_password] = useState("")

    function login(e){
        e.preventDefault();
        setPassword(sha256(password))
        set_password("")
    }
    return (
        <ThemeProvider theme={theme}>
            <Paper className='container' sx={{border: 'none', borderRadius: '0px'}}>
            <div style={{
          color: colors.on_primary,
          backgroundColor: colors.primary,
        }} className="titleBar">
          Contractinator
        </div>
                <Box sx={{margin: '20px auto', textAlign: 'center'}}>
                    <form onSubmit={login}>
                        <label>
                            <TextField id="passwordField" label="Wachtwoord" type='password' autoComplete='current-password' onChange={(e) => set_password(e.target.value)} value={password}></TextField>
                        </label>
                        <div>
                            <Button variant='contained' type="submit" sx={{marginTop: '20px'}}>Inloggen</Button>
                        </div>
                    </form>
                </Box>
            </Paper>
        </ThemeProvider>
    )
}

export default Login;